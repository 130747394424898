import React, { useEffect, useState } from "react";
import { Images } from "./Images";
import { RenderToHtmlID } from "./renderToHtmlID";
import { BlogPost } from "./components/BlogPost/BlogPost";
import { apiGetFacebookPage } from "./api/apiGetFacebookPage";
import { IPageData } from "./types";
import { Footer } from "./components/Footer/Footer";

function App() {
  const [page, setPage] = useState<IPageData | undefined>(undefined);

  useEffect(() => {
    apiGetFacebookPage()
      .then((r) => {
        const pg: IPageData = r.data.data;
        pg.places = [
          {
            title: "Galerie",
            address_line1: "U Výstaviště 138/3",
            city: "Praha 7 - Holešovice",
            post_code: "170 00",
            email: "galerie@alesbalek.art",
            phoneNumber: "+420 773 949 499",
          },
          {
            title: "Sídlo",
            address_line1: "Ortenovo náměstí 1213/28",
            city: "Praha 7 - Holešovice",
            post_code: "170 00",
            email: "tvorba@alesbalek.art",
            phoneNumber: "+420 773 949 499",
          },
        ];
        setPage(pg);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div className="App">
      <RenderToHtmlID htmlId="cmp1">
        {page?.about && (
          <BlogPost
            texts={[
              {
                sid: "fsdfsdf",
                content: page.about,
                format: "plain",
                wrapperElement: {
                  tag: "div",
                  attrs: [],
                },
              },
            ]}
          />
        )}
      </RenderToHtmlID>
      <RenderToHtmlID htmlId="cmp2">
        <Images />
      </RenderToHtmlID>
      <RenderToHtmlID htmlId="footer">
        <Footer />
      </RenderToHtmlID>
    </div>
  );
}

export default App;
