import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { apiGetConfig } from "./api/apiGetConfig";

const main = async () => {
  const config = await apiGetConfig();
  // ReactDOM.createPortal(
  //   <button style={{ marginLeft: "10px" }}>Click</button>,
  //   // @ts-ignore
  //   document.getElementById("cmp1")
  // );
  // ReactDOM.createPortal(
  //   <App />,
  //   // @ts-ignore
  //   document.getElementById("cmp1")
  // );

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

main().then((r: any) => {
  console.log("MAIN DONE");
  console.log(r);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
