import React, { useEffect, useState } from "react";
import "./App.css";
import { apiGetImagesFromFacebook } from "./api/apiGetImagesFromFacebook";
import { IHtmlImage } from "./HtmlComponents/HtmlImage";
import { MetroGrid } from "./components/Album/MetroGrid";
import ReactDOM from "react-dom";

const renderImages = (images: IHtmlImage[]) => {
  return <MetroGrid images={images} />;
  // retuy<MetroGrid />
  // return images.map((img: IHtmlImage) => <HtmlImage key={img.id} {...img} />);
};

// export const ImagesWrapper = () => {
//   return ReactDOM.createPortal(
//     <Images />,
//     // @ts-ignore
//     document.getElementById("cmp1")
//   );
// };

export const Images = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    apiGetImagesFromFacebook().then((r) => {
      setImages(r.data.data.data);
    });
  }, []);
  return <div className="App">{renderImages(images)}</div>;
};
