export interface IFooter {}

export const Footer = ({}) => {
  return (
    <div className="content-wrapper content-padding">
      <div className="columns-wrapper">
        {/* <h3>
          Kontaktní <strong>informace</strong>
        </h3> */}
        <div className="footer-container">
          <p>
            <strong>Galerie</strong>
            <br />
            U Výstaviště 122
            <br />
            Praha 7 - Holešovice
            <br />
            <a href="mailto:galerie@alesbalek.art">
              <strong>galerie@alesbalek.art</strong>
            </a>
          </p>
          <p>
            <strong>Sídlo</strong>
            <br />
            Ortenovo náměstí 1213/28
            <br />
            Praha 7 - Holešovice
            <br />
            <a href="mailto:tvorba@alesbalek.art">
              <strong>tvorba@alesbalek.art</strong>
            </a>
          </p>
          <p>
            <strong>Kontakty</strong>
            <br />
            +420 773 949 499
            <br />
            <br />
            <a
              href="https://www.facebook.com/alesbalek.art"
              title="Facebook"
              className="ss"
              target="_blank"
            >
              <img
                loading="lazy"
                src="assets/img/ico-facebook-white.svg"
                alt="Facebook"
                width="20"
                height="20"
              />
            </a>
            <a
              href="https://www.instagram.com/alesbalek.art"
              title="Instagram"
              className="ss"
              target="_blank"
            >
              <img
                loading="lazy"
                src="assets/img/ico-instagram-white.svg"
                alt="Instagram"
                width="20"
                height="20"
              />
            </a>
          </p>
        </div>
        {/* <div>
          <a href="mailto:tvorba@alesbalek.art">
            <strong>tvorba@alesbalek.art</strong>
          </a>
          <br />
          +420 773 949 499
        </div>
        <div className="tar">
          <a href="#" title="Facebook" className="ss" target="_blank">
            <img
              loading="lazy"
              src="assets/img/ico-facebook-white.svg"
              alt="Facebook"
              width="20"
              height="20"
            />
          </a>
          <a href="#" title="Instagram" className="ss" target="_blank">
            <img
              loading="lazy"
              src="assets/img/ico-instagram-white.svg"
              alt="Instagram"
              width="20"
              height="20"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};
