import { ISid } from "../types";

export interface IHtmlImage {
  sid: ISid;
  src: string;
  height: number;
  width: number;
  name: string;
  images: any;
}

export const HtmlImage = ({ src, width, height, images, name }: IHtmlImage) => {
  return (
    <img
      src={images[0].source}
      width={images[0].width / 8}
      height={images[0].height / 8}
      alt={name}
    />
  );
};
