import { IHtmlImage } from "../../HtmlComponents/HtmlImage";
import { MetroItem } from "./MetroItem";
import "./MetroGrid.css";

export interface IMetroGrid {
  images: IHtmlImage[];
}

export const MetroGrid = ({ images }: IMetroGrid) => {
  return (
    <div id="section-about">
      <div className="content-wrapper content-padding">
        <h2>Tvorba</h2>
        <div className="metro-container">
          {images.map((image: IHtmlImage) => (
            // @ts-ignore
            <MetroItem key={image.id} {...image} />
          ))}
        </div>
      </div>
    </div>
  );
};
