import React, { ReactElement } from "react";
import ReactDOM from "react-dom";

export interface IRenderToHtmlID {
  children: ReactElement | ReactElement[] | any;
  htmlId: string;
}

export const RenderToHtmlID = ({ children, htmlId }: IRenderToHtmlID) => {
  return ReactDOM.createPortal(
    children,
    // @ts-ignore
    document.getElementById(htmlId)
  );
};
