import { ReactElement } from "react";
import { IHtmlImage } from "../../HtmlComponents/HtmlImage";

export interface IHtmlAttr {
  name: string;
  value: string;
}

export interface IHtmlElement {
  tag: string;
  attrs: IHtmlAttr[];
}

export interface ITextContent {
  sid: string;
  content: string;
  format: "plain" | "html";
  wrapperElement: IHtmlElement;
}

export interface IBlockPostImage {
  image: IHtmlImage;
  position: "left" | "right";
}

export interface IBlockPost {
  texts: ITextContent[];
  image?: IBlockPostImage;
}

const TextPart = ({ text }: { text: ITextContent }) => (
  <div id="section-about">
    <div className="content-wrapper content-padding">
      <div className="summary">
        {/* <h2>Dopravní společnost <strong>BUSCENTRUM</strong></h2> */}
        <blockquote>
          {text.content}
          {/* Firma byla založena v roce 1990. Od doby svého vzniku přepravila
            mnoho spokojených klientů. Společnost se zabývá dopravou osob v
            individuální, incomingové, kongresové, tuzemské i zahraniční
            zájezdové dopravě.
            <strong
              >Jsme připraveni pro Vás zajistit nejvhodnější přepravu,
              kalkulace, časové harmonogramy.</strong
            > */}
        </blockquote>
        {/* <a href="onas.html" class="btn b2">Více o nás</a> */}
      </div>
      {/* <figure>
          <img
            loading="lazy"
            src="assets/img/about.jpg"
            alt=""
            width="418"
            height="418"
          />
        </figure> */}
    </div>
  </div>
);

export const BlogPost = ({ texts, image }: IBlockPost): ReactElement => {
  return (
    <>
      {texts.map((text: ITextContent) => {
        // return <div key={text.id}>{text.content}</div>;
        return <TextPart key={text.sid} text={text} />;
      })}
    </>
  );
};
